function MyButton() {
  return (
    <button>
      I'm a button
    </button>
  );
}

function MyText()
{
  return (
    <span><h1>Hello World</h1><br /></span> 
    
  )
}

 function MyApp() {
  return (
    <div>
      <MyText />
      <MyButton />
      xxx

    </div>
  );
}

 export default MyApp;
